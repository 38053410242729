<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
        <b-tabs>
          <b-tab active title="English">
            <b-card title="FAQ Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
            <b-card title="Description">
           <b-row>
            <b-col cols="12">
              <b-form-group>
                <ckeditor v-model="page.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Question Type Section">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestion(null)"
          >
            <b-row
              v-for="(question, index) in page.questionType"
              :key="index"
              ref="descriptionrow"
            >
              <b-col cols="9">
                <b-form-group label="QuestionType">
                  <b-form-input
                    v-model="page.questionType[index].title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestion(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card latitlebel="Question Series">
                <b-form
                label="Question Series"
                 ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestionSeries(null, index)">
                <b-row
              v-for="(question, index1) in page.questionType[index].questionList"
              :key="index1"
              ref="questionRow"
            >
              <b-col cols="9">
                <b-form-group :label="'Question'+ (index1+1)">
                  <quill-editor v-model="page.questionType[index].questionList[index1].title" :options="snowOption" />
                  <quill-editor class="mt-1" v-model="page.questionType[index].questionList[index1].description" :options="snowOption" />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestionSeries(null, index, index1)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
                </b-row>
                <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestionSeries(null, index)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.questionType[index].questionList.length === 0
                      ? "Add Series Question"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
              </b-form>
              </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestion(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                  page.questionType.length === 0
                      ? "Add Question Section"
                      : "Add New Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
          </b-tab>

          <b-tab title="Hindi">
            <b-card title="FAQ Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
            <b-card title="Description">
           <b-row>
            <b-col cols="12">
              <b-form-group>
                <ckeditor v-model="page.translation.hi.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Question Type Section">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestion('hi')"
          >
            <b-row
              v-for="(question, index) in page.translation.hi.questionType"
              :key="index"
              ref="descriptionrow"
            >
              <b-col cols="9">
                <b-form-group label="QuestionType">
                  <b-form-input
                    v-model="page.translation.hi.questionType[index].title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestion('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card latitlebel="Question Series">
                <b-form
                label="Question Series"
                 ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestionSeries('hi', index)">
                <b-row
              v-for="(question, index1) in page.translation.hi.questionType[index].questionList"
              :key="index1"
              ref="questionRow"
            >
              <b-col cols="9">
                <b-form-group :label="'Question'+ (index1+1)">
                  <quill-editor v-model="page.translation.hi.questionType[index].questionList[index1].title" :options="snowOption" />
                  <quill-editor class="mt-1" v-model="page.translation.hi.questionType[index].questionList[index1].description" :options="snowOption" />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestionSeries('hi', index, index1)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
                </b-row>
                <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestionSeries('hi', index)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.hi.questionType[index].questionList.length === 0
                      ? "Add Series Question"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
              </b-form>
              </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestion('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                  page.translation.hi.questionType.length === 0
                      ? "Add Question Section"
                      : "Add New Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
          </b-tab>

          <b-tab title="Marathi">
            <b-card title="FAQ Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
            <b-card title="Description">
           <b-row>
            <b-col cols="12">
              <b-form-group>
                <ckeditor v-model="page.translation.mr.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Question Type Section">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestion('mr')"
          >
            <b-row
              v-for="(question, index) in page.translation.mr.questionType"
              :key="index"
              ref="descriptionrow"
            >
              <b-col cols="9">
                <b-form-group label="QuestionType">
                  <b-form-input
                    v-model="page.translation.mr.questionType[index].title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestion('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card latitlebel="Question Series">
                <b-form
                label="Question Series"
                 ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatQuestionSeries('mr', index)">
                <b-row
              v-for="(question, index1) in page.translation.mr.questionType[index].questionList"
              :key="index1"
              ref="questionRow"
            >
              <b-col cols="9">
                <b-form-group :label="'Question'+ (index1+1)">
                  <quill-editor v-model="page.translation.mr.questionType[index].questionList[index1].title" :options="snowOption" />
                  <quill-editor class="mt-1" v-model="page.translation.mr.questionType[index].questionList[index1].description" :options="snowOption" />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeQuestionSeries('mr', index, index1)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  Remove Section
                </b-button>
              </b-col>
                </b-row>
                <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestionSeries('mr', index)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.mr.questionType[index].questionList.length === 0
                      ? "Add Series Question"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
              </b-form>
              </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatQuestion(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                  page.translation.mr.questionType.length === 0
                      ? "Add Question Section"
                      : "Add New Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
          </b-tab>
        </b-tabs>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    quillEditor,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      isDraftAvailable: false,
      page: {
        pageTitle: "",
        description: "",
        questionType: [],
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            questionType: []
          },
          mr: {
            pageTitle: "",
            description: "",
            questionType: []
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatQuestion(lang = undefined) {
      if (lang) {
        this.page.translation[lang].questionType.push({
          title: "",
          questionList: []
        });
      } else {
        this.page.questionType.push({
          title: "",
          questionList: []
        });
      }
    },
    repeatQuestionSeries(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].questionType[index].questionList.push({
          title: "",
          description: ""  
        });
      } else {
        this.page.questionType[index].questionList.push({
          title: "",
          description: ""
        });
      }
    },
    removeQuestionSeries(lang = undefined, index, index1) {
      if (lang) {
        this.page.translation[lang].questionType[index].questionList.splice(index1, 1);
      } else {
        this.page.questionType[index].questionList.splice(index1, 1);
      }
    },
    removeQuestion(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].questionType.splice(index, 1);
      } else {
        this.page.questionType.splice(index, 1);
      }
    },
    repeatCategory(lang = undefined) {
      if (lang) {
        this.page.translation[lang].categories.push({
          title: "",
          icon: "",
          description: "",
          buttonTitle: "",
        });
      } else {
        this.page.categories.push({
          title: "",
          icon: "",
          description: "",
          buttonTitle: "",
        });
      }
    },
    removeCategory(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].categories.splice(index, 1);
      } else {
        this.page.categories.splice(index, 1);
      }
    },
    handleCategoryIconMedia(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.page.translation[mediaObject.params.lang].categories[
            mediaObject.params.index
          ].icon = mediaObject.media.src;
        } else {
          this.page.categories[mediaObject.params.index].icon =
            mediaObject.media.src;
        }
      }
    },
    saveDraft() {
      localStorage.setItem("faq-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("faq-data");
      this.page = JSON.parse(page);
    },
    removeDraft() {
      localStorage.removeItem("faq-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/faq/all`)
        .then((response) => {
          this.page = response.data[0]
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/faq/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log('res', response.data)
           this.makeToast(
                "success",
                "Success",
                `${this.page.pageTitle} has been updated successfully!`
              );
        })
        .catch((error) => {
          console.log(error.response);
           this.makeToast(
                "danger",
                "Error",
                "Error while updating the event!"
              );
        });
    },
  },
  mounted() {
    if (localStorage.getItem("faq-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style>
</style>